import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="members-index-form"
export default class extends Controller {
  initialize() {
  }

  submit() {
    this.element.requestSubmit();
  }

}
