import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="event-registrations"
export default class extends Controller {

  closeModal() {
    document.getElementsByName('close-btn').forEach((e) => e.click())
  }

  submit() {
    this.element.requestSubmit();
  }

  pay(e) {
    let payLink = document.getElementById('payment-link').value
    if (payLink !== '' && payLink) {
      window.open(payLink, '_blank')
    } else {
      e.preventDefault()
    }
  }
}
