document.addEventListener("turbo:load", () => {
    const advancedSearchButton = document.getElementById("advanced-search-button");
    const advancedSearchSection = document.getElementById("advanced-search-section");


    advancedSearchButton?.addEventListener("click", () => {
        if (advancedSearchSection.style.display === "none") {
            advancedSearchSection.style.display = "block";
        } else {
            advancedSearchSection.style.display = "none";
        }
    });
})