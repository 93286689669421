import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["nextButton", "prevButton", "image", "content"];

    connect() {
        this.currentIndex = 0;
    }

    updateCarousel(action) {
        // this.imageTargets.forEach((image, index) => {
        //     if (index === this.currentIndex) {
        //         image.classList.remove('hidden', 'slide-out-next', 'slide-out-prev');
        //         if (action === 'next') {
        //             image.classList.add('slide-in-next');
        //         } else {
        //             image.classList.add('slide-in-prev');
        //         }
        //     } else {
        //         image.classList.remove('slide-in-next', 'slide-in-prev');
        //         if (action === 'next') {
        //             image.classList.add('hidden', 'slide-out-next');
        //         } else {
        //             image.classList.add('hidden', 'slide-out-prev');
        //         }
        //     }
        // });

        this.contentTargets.forEach((content, index) => {
            if (index === this.currentIndex) {
                content.classList.remove('hidden');
            } else {
                content.classList.add('hidden');
            }
        });
    }

    next() {
        this.currentIndex = (this.currentIndex + 1) % this.contentTargets.length;
        this.updateCarousel('next');
    }

    prev() {
        this.currentIndex =
            (this.currentIndex - 1 + this.contentTargets.length) % this.contentTargets.length;
        this.updateCarousel('prev');
    }

}
