import Reveal from 'stimulus-reveal-controller';

export default class extends Reveal {

    static targets = ["trigger", "toggleable"];

    change() {
        // const icon = iconTarget;
        const label = this.triggerTarget.querySelector('span');
        const icon = this.triggerTarget.querySelector('i');


        if (label.innerText == "Close" && !this.toggleableTarget.classList.contains('hidden')) {
            label.innerText = 'Expand';
            label.classList.remove('text-fade');
            setTimeout(() => {
              label.classList.add('text-fade');
            }, '100');
            icon.style.transform = 'rotate(180deg)';
        } else {
            label.innerText = 'Close';
            label.classList.remove('text-fade');
            setTimeout(() => {
              label.classList.add('text-fade');
            }, '100');
            icon.style.transform = 'rotate(0deg)';
        }
    }
}