import { Controller } from "@hotwired/stimulus"

// Turbo requests doesnt update current request parameters after filtering users.
// custom trigger csv download with the current filters
export default class extends Controller {
  static targets = ["form"];

  download() {
    window.open(this.urlWithQueryString(), '_blank');
  }

  queryString() {
    const form = new FormData(this.formTarget);
    const params = new URLSearchParams();
    for (const [name, value] of form.entries()) {
      params.append(name, value);
    }

    return params.toString();
  }

  pathname() {
    return window.location.pathname;
  }

  origin() {
    return window.location.origin
  }

  urlWithQueryString() {
    return `${this.origin()}/users/search.csv?${this.queryString()}`;
  }
}
