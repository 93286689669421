import { Controller } from "@hotwired/stimulus"
import SlimSelect from "slim-select"

export default class extends Controller {
    static values = { showSearch: Boolean, closeOnSelect: Boolean }

    connect() {
        new SlimSelect({
            select: this.element,
            settings: {
                showSearch: !!this.showSearchValue,
                closeOnSelect: !!this.closeOnSelectValue,
                allowDeselect: true,
                maxValuesShown: 4, // Default 20
                maxValuesMessage: '{number} values selected', // Default '{number} selected',
                placeholderText: 'Please select'
            }
        })
    }
}