import { Controller } from "@hotwired/stimulus"

const tabsObserver = new MutationObserver(function (e) {
  e.forEach(element => {
    // update relevant count number text by finding ${status}-count id string
    document.getElementById(`${element.target.id.split('_')[0]}-count`).innerText = `(${element.target.children.length})`;
  })
})

export default class extends Controller {

  connect() {
    tabsObserver.observe(document.getElementById('approved_noncnx_registrants'), { childList: true });
    tabsObserver.observe(document.getElementById('denied_noncnx_registrants'), { childList: true });
    tabsObserver.observe(document.getElementById('pending_noncnx_registrants'), { childList: true });
  }

  disconnect() {
    tabsObserver.disconnect();
  }
}
