import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="events"
export default class extends Controller {
  static targets = ["price", "free", "online", "stripeLink", "location", "createZoomMeetingOption", "zoomTemplate", "onlineId"]

  connect() {
    this.priceTarget.disabled = this.freeTarget.checked;
    this.stripeLinkTarget.disabled = this.freeTarget.checked;
    this.onlineIdTarget.disabled = this.createZoomMeetingOptionTarget.checked;
    this.locationTarget.disabled = this.onlineTarget.checked;
  }

  free() {
    this.priceTarget.disabled = this.freeTarget.checked;
    this.stripeLinkTarget.disabled = this.freeTarget.checked;
    if (this.freeTarget.checked) {
      this.priceTarget.value = 0;
    }

    this.locationTarget.disabled   = this.onlineTarget.checked;
    if (this.onlineTarget.checked) {
      this.locationTarget.value = "";
      this.showZoomForm(true)
    } else {
      this.showZoomForm(false)
    }
  }

  toggleZoomMeeting() {
    if (this.createZoomMeetingOptionTarget.checked) {
      this.onlineIdTarget.disabled = true
      this.onlineIdTarget.value = ''
      this.zoomTemplateTarget.disabled = false;
    } else {
      this.onlineIdTarget.disabled = false
      document.querySelector('div[class="ss-deselect"]').click(); // clear out the selected option
      this.zoomTemplateTarget.disabled = true;
    }
  }

  showZoomForm(value) {
    Array.from(document.getElementsByClassName('zoom-meeting-form')).forEach(element => {
      value ?
        element.classList.remove('hidden')
        : element.classList.add('hidden')
    });
  }
}
