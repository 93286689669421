import { Controller } from "@hotwired/stimulus"
import Cropper from "cropperjs";
import { Modal } from "tailwindcss-stimulus-components";

export default class Customcrop extends Modal {
    static targets = ["modal", "image", "xOffset", "yOffset", "width", "height"];

    connect() {
        super.connect();
    }

    open(event) {
        super.open(event);

        const controller = this; // needed, because this gets overwritten in the cropper callback

        this.cropper = new Cropper(this.imageTarget, {

            aspectRatio: 1,
            restore: false,

            crop(image) {
                controller.xOffsetTarget.value = image.detail.x;
                controller.yOffsetTarget.value = image.detail.y;
                controller.widthTarget.value = image.detail.width;
                controller.heightTarget.value = image.detail.height;
            },
        });

        document.getElementById('crop-button').addEventListener('click', function () {
            event.preventDefault();

            const params = new URLSearchParams();

            params.append('x_offset', controller.xOffsetTarget.value);
            params.append('y_offset', controller.yOffsetTarget.value);
            params.append('width', controller.widthTarget.value);
            params.append('height', controller.heightTarget.value);

            fetch(`crop_avatar`, {
                    method: 'POST',
                    body: params,
                    headers: {
                        'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").content,
                        'Cache-Control': 'no-store'
                    },
                    credentials: 'same-origin'
                }
            ).then(response => {
                controller.close(event);
                window.location.reload();
            })
        });
    }

    onclose(event) {
        this.cropper.destroy();
    }
}
