import { Application } from "@hotwired/stimulus"

const application = Application.start()

// Configure Stimulus development experience
application.debug    = false
window.Stimulus      = application

export { application }

// custom event listener for button confirm attribute handling
document.addEventListener("click", event => {
  const element = event.target.closest("[data-confirm]")

  if (element && !confirm(element.dataset.confirm)) {
    event.preventDefault()
  }
})