function reorderSteps() {
    const stepsContainer = document.querySelector('.steps-container');
    const steps = stepsContainer?.querySelectorAll('.step-content');
    const innerStepsContainers = stepsContainer?.querySelectorAll('.step-content-wrapper');

    if (window.innerWidth < 1024) {
        innerStepsContainers?.at(0)?.appendChild(steps[3]); // step 2
        innerStepsContainers?.at(0)?.appendChild(steps[4]); // step 4
    } else {
        innerStepsContainers?.at(1)?.insertBefore(steps[3], steps[1]); // step 2
        innerStepsContainers?.at(1)?.appendChild(steps[4]); // step 4
    }


}

// reorder when resizing and page loading
window.addEventListener('resize', reorderSteps);
window.addEventListener('DOMContentLoaded', reorderSteps)
